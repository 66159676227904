<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            :placeholder="['项目开始时间', '项目结束时间']"
            style="width: 400px !important"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="getTime"
          ></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 200px"
            v-model="page.state"
            placeholder="请选择状态"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">有效</a-select-option>
            <a-select-option value="2">无效</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="$exportFun('/export/project', page, 'projectManage')"
            >导出</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ x: 1450, y: tableHeigt }"
      >
        <template slot="state" slot-scope="text">
          <a-tag color="green" v-if="text == '1'">有效</a-tag>
          <a-tag color="orange" v-if="text == '2'">无效</a-tag>
        </template>
        <template slot="is_income_tax" slot-scope="text">
          <a-tag color="green" v-if="text == '1'">启用</a-tag>
          <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
        </template>

        <template slot="project_status" slot-scope="text">
          <a-tag v-if="text == '1'">项目上线</a-tag>
          <a-tag v-if="text == '2'">项目下线</a-tag>
          <a-tag v-if="text == '3'">项目完成</a-tag>
        </template>

        <template slot="is_special" slot-scope="text">
          <span v-if="text == '1'">是</span>
          <span v-if="text == '2'">否</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            icon="edit"
            @click="() => editAction(record['id'])"
            >编辑</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="user"
            @click="() => showPro_user(record['id'])"
            >查看人员</a-button
          >
          <a-button
            v-if="record.is_delete == 2"
            type="link"
            size="small"
            icon="delete"
            @click="() => delConfirm(record['id'])"
            >删除</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="minus-circle"
            v-if="record.is_delete == 2 && record['state'] == '1'"
            @click="() => enabledState(record['id'], 2, '你确定要禁用吗？')"
            >禁用</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="check-circle"
            v-if="record.is_delete == 2 && record['state'] == '2'"
            @click="enabledState(record['id'], 1, '你确定要启用吗？')"
            >启用</a-button
          >
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>

    <a-modal
      title="项目管理"
      :visible="actionVisible"
      @ok="actionData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :width="800"
      :confirmLoading="$store.state.requestLoading"
    >
      <div style="max-height: 500px; overflow-y: auto">
        <a-form-model
          ref="formModel"
          :rules="formModelRules"
          :model="formModel"
          layout="vertical"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item label="项目编号" prop="code">
            <a-input
              v-model="formModel.code"
              placeholder="请输入项目编号"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="项目名称" prop="name">
            <a-input
              v-model="formModel.name"
              placeholder="请输入项目名称"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="合同" prop="contract_id">
            <a-select
              v-model="formModel.contract_id"
              placeholder="请选择合同"
              :disabled="!isAdd"
            >
              <a-select-option
                v-for="(item, index) in ContractJson"
                :key="index"
                :value="item.id"
                >{{ item.title }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="协议" prop="contract_agreement_id">
            <a-select
              v-model="formModel.contract_agreement_id"
              placeholder="请选择协议"
              :disabled="!isAdd"
            >
              <a-select-option
                v-for="(item, index) in agreementJson"
                :key="index"
                :value="item.id"
                >{{ item.title }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="方案" prop="plan_id">
            <a-select
              v-model="formModel.plan_id"
              placeholder="请选择方案"
              @change="planChange"
              :disabled="!isAdd"
            >
              <a-select-option
                v-for="(item, index) in planJson"
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="劳务队" prop="team_ids">
            <a-select
              placeholder="请选择劳务队"
              mode="multiple"
              @change="teamChange"
              v-model="formModel.team_ids"
            >
              <a-select-option
                v-for="(item, index) in teamJson"
                :key="index"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="项目开始时间" prop="start_time">
            <a-date-picker
              style="width: 100%"
              v-model="formModel.start_time"
              :disabled-date="disabledStartDate"
              value-format="YYYY-MM-DD"
              placeholder="请选择项目开始时间"
            />
          </a-form-model-item>
          <a-form-model-item label="项目结束时间" prop="end_time">
            <a-date-picker
              style="width: 100%"
              v-model="formModel.end_time"
              :disabled-date="disabledEndDate"
              value-format="YYYY-MM-DD"
              placeholder="请选择项目结束时间"
            />
          </a-form-model-item>
          <a-form-model-item label="项目描述" prop="describe">
            <a-input
              v-model="formModel.describe"
              placeholder="请输入项目描述"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="维权信息" prop="rights_information">
            <a-input
              v-model="formModel.rights_information"
              placeholder="请输入维权信息"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="项目状态" prop="project_status">
            <a-select
              v-model="formModel.project_status"
              placeholder="请选择项目状态"
            >
              <a-select-option value="1">项目上线</a-select-option>
              <a-select-option value="2">项目下线</a-select-option>
              <a-select-option value="3">项目完成</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="项目地址" prop="city_name">
            <area-select
              type="all"
              v-model="cityData"
              :level="2"
              :data="pcaa"
            ></area-select>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="detail_addr">
            <a-input
              v-model="formModel.detail_addr"
              placeholder="请输入详细地址"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="经度" prop="x_coordinates">
            <a-input
              v-model="formModel.x_coordinates"
              placeholder="请输入项目地址X坐标"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="纬度" prop="y_coordinates">
            <a-input
              v-model="formModel.y_coordinates"
              placeholder="请输入项目地址Y坐标"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="打卡范围" prop="radius">
            <a-input
              style="width: 420px"
              v-model="formModel.radius"
              placeholder="请输入打卡范围"
              suffix="米"
            ></a-input>
            <a-button
              style="margin-left: 15px"
              @click="Mapisible = true"
              type="primary"
            >
              在地图上选择
            </a-button>
          </a-form-model-item>
          <a-form-model-item label="打卡时间" prop="radius">
            <div
              v-for="(item, index) in formModel.clock_time_range"
              :key="index"
              style="margin-bottom: 5px"
            >
              <a-time-picker
                style="width: 207px"
                value-format="HH:mm:ss"
                v-model="item.start_time"
              />
              -
              <a-time-picker
                style="width: 200px"
                value-format="HH:mm:ss"
                v-model="item.end_time"
              />
              <a-button
                style="margin-left: 15px"
                type="primary"
                v-if="index == 0"
                @click="addclock"
              >
                新增
              </a-button>
              <a-button
                style="margin-left: 15px"
                type="danger"
                v-else
                @click="delclock(index)"
              >
                移除
              </a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="是否启用个税" prop="is_income_tax">
            <a-radio-group :disabled="!isAdd" v-model="formModel.is_income_tax">
              <a-radio :value="1"> 启用 </a-radio>
              <a-radio :value="2"> 禁用 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="管理费" prop="management_fee_ratio">
            <a-input
              v-model="formModel.management_fee_ratio"
              suffix="%"
              placeholder="请输入管理费"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="增值税" prop="tax">
            <a-input
              v-model="formModel.tax"
              suffix="%"
              placeholder="请输入增值税"
            ></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <a-modal
      :width="1000"
      v-if="Mapisible"
      v-model="Mapisible"
      title="坐标与打卡范围选择"
      :footer="null"
    >
      <map-model
        :lngs="formModel.x_coordinates"
        :lats="formModel.y_coordinates"
        :radiuss="formModel.radius"
        @GetMapData="GetMapData"
      ></map-model>
    </a-modal>
    <a-modal
      title="查看人员"
      :visible="userVisible"
      @ok="actionUserData"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :width="1500"
      :confirmLoading="$store.state.requestLoading"
    >
      <div style="">
        <div style="margin-bottom: 10px">
          <a-form layout="inline">
            <a-form-item>
              <a-input
                v-model="userpage.key"
                placeholder="请输入关键字"
              ></a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 200px"
                v-model="userpage.state"
                placeholder="请选择性别"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="男">男</a-select-option>
                <a-select-option value="女">女</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 200px"
                v-model="userpage.work_id"
                placeholder="请选择工种"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in jobJson"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-range-picker
                :placeholder="['入厂开始时间', '入场结束时间']"
                style="width: 400px !important"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                @change="getTime2"
              ></a-range-picker>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="searchuList" icon="search"
                >搜索</a-button
              >
            </a-form-item>
            <a-form-item>
              <a-button
                :disabled="selectedRowKeys.length == 0"
                @click="openForceOutData(selectedRowKeys)"
                icon="close-circle"
                type="danger"
                >批量强制退场</a-button
              >
            </a-form-item>
          </a-form>
        </div>
        <a-alert
          style="margin-bottom: 10px"
          :message="'当前项目下共有人员：' + `${userpage.totalRow}` + '人'"
          type="info"
        />
        <a-table
          :columns="usercolumns"
          :data-source="userlist"
          :scroll="{ x: 1450, y: 500 }"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          rowKey="id"
          bordered
          :pagination="false"
        >
          <template slot="state" slot-scope="text, record">
            <a-tag color="green" v-if="text == '1'">启用</a-tag>
            <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
          </template>
          <template slot="sex" slot-scope="text, record">
            <span v-if="text == '0'">未知</span>
            <span v-if="text == '1'">男</span>
            <span v-if="text == '2'">女</span>
          </template>
          <template slot="idno_positive_img_url" slot-scope="text, record">
            <img
              :src="text + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
              style="width: 90px; height: 60px"
              @click="
                imgvisible = true;
                viewImgData = text;
              "
              alt=""
            />
          </template>
          <template slot="action" slot-scope="text, record">
            <a-button
              size="small"
              icon="close-circle"
              type="danger"
              @click="() => openForceOutData(record['id'])"
              >强制退场</a-button
            >
          </template>
        </a-table>
        <div class="page-cu-pagination">
          <a-pagination
            :show-total="(total, range) => `总数：${total} 条`"
            :page-size.sync="userpage.pageSize"
            :total="userpage.totalRow"
            v-model="userpage.start"
            @change="changeuserPage"
          />
        </div>
      </div>
    </a-modal>
    <a-modal
      title="强制退场"
      :visible="forceOutVisible"
      @ok="forceOut"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <a-form-model
        ref="forceOutForm"
        :rules="forceOutRules"
        :model="forceOutData"
        layout="vertical"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="原因" prop="audit_state">
          <a-select
            placeholder="请选择强制退场原因"
            v-model="forceOutData.apply_reason"
          >
            <a-select-option
              :value="item.apply_reason"
              v-for="(item, index) in outReasonsData"
              :key="index"
              >{{ item.apply_reason }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="强制退场理由" prop="apply_reason">
          <a-input
            type="textarea"
            row="2"
            v-model="forceOutData.apply_reason"
          ></a-input>
        </a-form-model-item> -->
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
import "vue-area-linkage/dist/index.css";
import { pca, pcaa } from "area-data";
import mapModel from "@/components/map";
export default {
  name: "index",
  components: {
    mapModel,
  },
  data() {
    // 数字校验
    const validatorNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入数字!"));
      } else {
        if (!/^[+-]?(0|([1-9]\d*))(\.\d+)?$/.test(value)) {
          callback(new Error("请输入数字!"));
        } else {
          callback();
        }
      }
    };
    return {
      selectedRowKeys: [],
      tableHeigt: 0,
      forceOutVisible: false,
      proId: "",
      outReasonsData: [],
      forceOutData: {
        id: "",
        user_ids: "",
        apply_reason: "",
      },
      userVisible: false,
      pca: pca,
      pcaa: pcaa,
      cityData: [],
      isAdd: true, // TODO: 是否是新增操作
      formModel: {
        id: "",
        code: "",
        contract_id: undefined,
        contract_agreement_id: undefined,
        plan_id: undefined,
        team_ids: undefined,
        work_ids: "",
        name: "",
        start_time: "",
        end_time: "",
        detailed_address: "",
        describe: "",
        rights_information: "",
        project_status: "",
        province_code: "",
        province_name: "",
        city_code: "",
        city_name: "",
        area_code: "",
        area_name: "",
        detail_addr: "",
        is_income_tax: "",
        management_fee_ratio: "",
        tax: "",
        x_coordinates: "",
        y_coordinates: "",
        radius: "",
        clock_time_range: [
          {
            start_time: "",
            end_time: "",
          },
        ],
      },

      formModelRules: {
        code: [
          { required: true, message: "请输入项目编号", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
        contract_id: [
          { required: true, message: "请选择合同", trigger: "change" },
        ],
        contract_agreement_id: [
          { required: true, message: "请选择协议", trigger: "change" },
        ],
        plan_id: [{ required: true, message: "请选择方案", trigger: "change" }],
        team_ids: [
          { required: true, message: "请选择劳务队", trigger: "change" },
        ],
        project_status: [
          { required: true, message: "请选择项目状态", trigger: "change" },
        ],
        management_fee_ratio: [
          { required: true, validator: validatorNumber, trigger: "change" },
        ],
        tax: [
          { required: true, validator: validatorNumber, trigger: "change" },
        ],
        is_income_tax: [
          { required: true, message: "请选择是否启用个税", trigger: "change" },
        ],
      },
      forceOutRules: {},
      team_ids: [],
      work_ids: [],
      columns: [
        {
          title: "项目编号",
          dataIndex: "code",
          width: 180,
        },
        {
          title: "项目名称",
          dataIndex: "name",
          width: 320,
        },
        {
          title: "项目开始时间",
          dataIndex: "start_time",
          width: 120,
        },
        {
          title: "项目结束时间",
          dataIndex: "end_time",
          width: 120,
        },

        {
          title: "项目描述",
          dataIndex: "describe",
          width: 250,
        },
        {
          title: "维权信息",
          dataIndex: "rights_information",
          width: 250,
        },
        {
          title: "所在省份",
          dataIndex: "province_name",
          width: 90,
        },
        {
          title: "所在地市",
          dataIndex: "city_name",
          width: 90,
        },
        {
          title: "所在区县",
          dataIndex: "area_name",
          width: 90,
        },
        {
          title: "详细地址",
          dataIndex: "detail_addr",
          width: 320,
        },
        {
          title: "项目状态",
          dataIndex: "project_status",
          scopedSlots: { customRender: "project_status" },
          width: 120,
          align: "center",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "个税",
          dataIndex: "is_income_tax",
          scopedSlots: { customRender: "is_income_tax" },
          width: 100,
          align: "center",
        },
        {
          title: "状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          width: 100,
          align: "center",
        },
        {
          title: "操作",
          width: 200,
          scopedSlots: { customRender: "action" },
          align: "center",
          fixed: "right",
        },
      ],
      usercolumns: [
        {
          title: "姓名",
          dataIndex: "name",
          width: 80,
          align: "center",
        },
        {
          title: "身份证号",
          dataIndex: "idno",
          width: 180,
        },
        {
          title: "手机号码",
          dataIndex: "mobile",
          width: 140,
        },
        {
          title: "出生日期",
          dataIndex: "birthday_time",
          width: 140,
        },
        // {
        //   title: "登录密码",
        //   dataIndex: "login_pwd",
        // },
        {
          title: "性别",
          dataIndex: "sex",
          width: 80,
          align: "center",
        },
        {
          title: "民族",
          dataIndex: "national",
          width: 80,
          align: "center",
        },
        {
          title: "身份证正面",
          dataIndex: "idno_positive_img_url",
          key: "idno_positive_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "身份证反面",
          dataIndex: "idno_reverse_img_url",
          key: "idno_reverse_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "头像",
          dataIndex: "head_img_url",
          key: "head_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "应急联系人",
          dataIndex: "contact_name",
          width: 140,
        },
        {
          title: "应急联系人手机号码",
          dataIndex: "contact_mobile",
          width: 160,
        },
        {
          title: "户籍所在地",
          dataIndex: "census_register_seat",
          width: 280,
        },

        {
          title: "状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          width: 100,
          align: "center",
        },

        // {
        //   title: "备注",
        //   dataIndex: "remark",
        //   width: 160,
        // },
        {
          title: "操作",
          width: 150,
          scopedSlots: { customRender: "action" },
          fixed: "right",
          align: "center",
        },
      ],
      WechatVisible: false,
      UpdateTimeRules: {
        service_end_time: [
          { required: true, message: "请选择服务结束时间", trigger: "change" },
        ],
      },
      WechatRules: {
        app_id: [
          { required: true, message: "请输入小程序appID", trigger: "change" },
        ],
        app_secret: [
          {
            required: true,
            message: "请输入小程序appsecret",
            trigger: "change",
          },
        ],
      },
      WechatData: {
        id: "",
        name: "",
        app_id: "",
        app_secret: "",
      },
      UpdateTimeVisible: false,
      UpdateTime: {
        id: "",
        name: "",
        service_start_time: "",
        service_end_time: "",
      },
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        state: undefined,
        key: "",
        start_time: "",
        end_time: "",
      },
      userpage: {
        id: "",
        totalRow: 0,
        pageSize: 0,
        start: 1,
        limit: 10,
        key: "",
        work_id: undefined,
        sex: undefined,
        start_time: "",
        end_time: "",
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      ContractJson: [], // 合同下拉数据
      agreementJson: [], // 协议下拉数据
      planJson: [], // 方案下拉数据
      teamJson: [], // 劳务队下拉数据
      jobJson: [], // 工种下拉数据
      userlist: [],
      Mapisible: false,
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
    });
  },
  methods: {
    /**
     * 添加，更新
     *
     */
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          try {
            this.formModel.province_name = Object.values(
              this.cityData[0]
            ).toString();
            this.formModel.province_code = Object.keys(
              this.cityData[0]
            ).toString();
            this.formModel.city_name = Object.values(
              this.cityData[1]
            ).toString();
            this.formModel.city_code = Object.keys(this.cityData[1]).toString();
            this.formModel.area_name = Object.values(
              this.cityData[2]
            ).toString();
            this.formModel.area_code = Object.keys(this.cityData[2]).toString();
          } catch (error) {
            console.log(this.cityData);
          }
          // this.formModel.work_ids = this.formModel.work_ids.join(",");
          this.formModel.team_ids = this.formModel.team_ids.join(",");
          let clock_time_range = [];

          this.formModel.clock_time_range.map((item) => {
            console.log(item);
            if (item.start_time != "" && item.end_time != "") {
              clock_time_range.push(item.start_time + "-" + item.end_time);
            }
          });
          this.formModel.clock_time_range = clock_time_range.join(",");
          let res = null;
          console.log(this.formModel);
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },

    /*
     * 表单重置
     **/
    resetFormModel() {
      for (let key in this.formModel) {
        if (
          key == "contract_id" ||
          key == "contract_agreement_id" ||
          key == "plan_id" ||
          key == "team_ids" ||
          key == "work_ids"
        ) {
          this.formModel[key] = undefined;
        } else {
          this.formModel[key] = "";
        }
        this.formModel.is_income_tax = 1;
        this.formModel.clock_time_range = [
          {
            start_time: "",
            end_time: "",
          },
        ];
      }
    },
    // 菜单选择
    handlerCheck(n, e) {
      this.checkedKeys = { checked: n, halfChecked: e.halfCheckedKeys };
    },
    // 打开添加项目窗
    addAction() {
      this.isAdd = true;
      this.resetFormModel();
      this.getContractJson();
      this.getagreementJson();
      this.getPlanJson();
      // this.getWorkJson();
      this.getTeamJson();
      this.actionVisible = true;
    },
    // 获取合同下拉数据
    async getContractJson() {
      let res = await Api.getContractJson({
        type: 1,
        state: "1",
        key: "",
      });
      this.ContractJson = res.list;
    },
    // 获取协议下拉数据
    async getagreementJson() {
      let res = await Api.getContractJson({
        type: 2,
        state: "1",
        key: "",
      });
      this.agreementJson = res.list;
    },
    // 获取方案下拉数据
    async getPlanJson() {
      let res = await Api.getPlanJson({ state: "1" });
      this.planJson = res.list;
    },

    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ state: "1" });
      this.jobJson = res.list;
    },
    // 获取劳务队下拉数据
    async getTeamJson() {
      let res = await Api.getTeamJson({ state: "1" });
      this.teamJson = res.list;
    },

    // 编辑
    async editAction(id) {
      this.isAdd = false;
      console.log(this.isAdd);
      // this.resetFormModel();
      this.getContractJson();
      this.getagreementJson();
      this.getPlanJson();
      // this.getWorkJson();
      this.getTeamJson();
      let res = await Api.DetailMenu({ id });
      Object.keys(this.formModel).forEach((key) => {
        this.formModel[key] = res.target[key];
      });
      this.formModel.team_ids = res.target.team_ids.split(",");
      this.formModel.team_ids = this.formModel.team_ids.map((item) =>
        Number(item)
      );
      if (
        res.target.clock_time_range != null &&
        res.target.clock_time_range != ""
      ) {
        let clock_time_range = res.target.clock_time_range.split(",");
        this.formModel.clock_time_range = [];
        if (clock_time_range.length != 0) {
          clock_time_range.map((item, index) => {
            this.formModel.clock_time_range.push({
              start_time: item.split("-")[0],
              end_time: item.split("-")[1],
            });
          });
        }
      }else{
        this.formModel.clock_time_range = [
          {
            start_time:"",
            end_time:""
          }
        ]
      }

      console.log("打卡时间", this.formModel.clock_time_range);

      // this.formModel.work_ids = res.target.work_ids.split(",");
      // this.formModel.work_ids = this.formModel.work_ids.map((item) =>
      //   Number(item)
      // );
      console.log(this.team_ids);
      // 省市县回显
      try {
        this.cityData[0] = this.pcaa[86][res.target.province_code];
        this.cityData[1] = this.pcaa[res.target.province_code][
          res.target.city_code
        ];
        this.cityData[2] = this.pcaa[res.target.city_code][
          res.target.area_code
        ]; //res.data.addrcountyid省对应的id
      } catch (error) {
        this.cityData = [];
        console.log(error);
      }
      console.log(this.cityData);
      this.actionVisible = true;
    },

    // 查看人员弹框
    showPro_user(id) {
      this.proId = id;
      this.userVisible = true;
      this.getUserList(id);
      this.getWorkJson();
    },
    // 获取用户列表
    async getUserList(id) {
      this.userpage.id = id;
      this.userlist = [];
      let res = await Api.UserList({ ...this.userpage });
      if (res && res.code == "0") {
        this.userpage.totalRow = res.page.totalRow;
        this.userpage.pageSize = res.page.pageSize;
        this.userlist = res.page.list;
      } else {
        this.userpage.totalRow = 0;
        this.userpage.pageSize = 0;
        this.userlist = "";
        this.$message.error(res.message);
      }
    },
    actionUserData() {
      this.userVisible = false;
    },
    // 关闭窗口
    cancel() {
      this.actionVisible = false;
      this.userVisible = false;
      this.forceOutVisible = false;
    },

    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    changeuserPage(currentPage) {
      this.userpage.start = currentPage;
      this.getUserList(this.proId);
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 人员搜索
    searchuList() {
      this.userpage.start = 1;
      this.getUserList(this.proId);
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    specialChange(value) {
      console.log(value.target.value);
      if (value.target.value == 2) {
        this.formModel.special_content = "";
      }
    },
    // TODO: 开始结束时间限制
    disabledStartDate(startValue) {
      const endValue = this.formModel.service_end_time;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.formModel.service_start_time;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    // 设置服务结束时间时
    disabledStartDate2(startValue) {
      const endValue = this.UpdateTime.service_end_time;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate2(endValue) {
      const startValue = this.UpdateTime.service_start_time;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 关闭窗口
    cancel() {
      this.actionVisible = false;
      this.UpdateTimeVisible = false;
      this.WechatVisible = false;
      this.forceOutVisible = false;
      this.userVisible = false;
    },
    teamChange(value) {
      this.formModel.team_ids = value;
      console.log(this.formModel.team_ids);
    },
    // 强制退场弹框
    async openForceOutData(id) {
      console.log(id);
      if (typeof id == "string") {
        this.forceOutData.user_ids = id;
      } else {
        this.forceOutData.user_ids = id.join(",");
      }
      this.forceOutData.id = this.proId;
      this.forceOutVisible = true;
      console.log(this.forceOutData);
      let res = await Api.outReasons({ state: 1 });
      if (res && res["code"] == "0") {
        this.outReasonsData = res.list;
      } else {
        this.$message.error(res.message);
      }
    },
    async forceOut() {
      console.log(this.forceOutData);
      let res = await Api.outUser({ ...this.forceOutData });
      if (res && res["code"] == "0") {
        this.forceOutVisible = false;
        this.$message.success(res.message);
        this.getUserList(this.proId);
        this.selectedRowKeys = [];
      } else {
        this.$message.error(res.message);
      }
    },
    planChange(value) {
      for (let index in this.planJson) {
        if (this.planJson[index].id == value) {
          this.formModel.work_ids = this.planJson[index].work_ids.join(",");
        }
      }
      console.log(this.formModel.work_ids);
    },
    getTime(t, v) {
      this.page.start_time = v[0];
      this.page.end_time = v[1];
    },
    getTime2(t, v) {
      this.userpage.start_time = v[0];
      this.userpage.end_time = v[1];
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.forceOutData.id = this.proId;
    },
    GetMapData(data) {
      this.Mapisible = false;
      this.formModel.x_coordinates = data.lng;
      this.formModel.y_coordinates = data.lat;
      this.formModel.radius = data.radius;
      console.log(data);
    },
    // 新增打卡时间
    addclock() {
      this.formModel.clock_time_range.push({
        start_time: "",
        end_time: "",
      });
    },
    delclock(index) {
      this.formModel.clock_time_range.splice(index, 1);
    },
  },
};
</script>

<style scoped>
</style>

