import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/project/page',
        method: 'GET',
        params
    });
}

// 保存
export function Save(data) {
    return request({
        url: '/admin/project/save',
        method: 'POST',
        data
    });
}

// 更新
export function Update(data) {
    return request({
        url: '/admin/project/update',
        method: 'POST',
        data
    });
}

// 详情
export function DetailMenu(params) {
    return request({
        url: '/admin/project/detail',
        method: 'GET',
        params
    });
}

// 删除
export function Delete(params) {
    return request({
        url: '/admin/project/delete',
        method: 'GET',
        params
    });
}

/**
 * 启用/禁用
 */
export function AdminEnabled(params) {
    return request({
        url: '/admin/project/changeIndex',
        method: 'GET',
        params
    });
}

/**
 * 设置服务结束时间
 */
 export function ChangeServiceEndTime(data) {
    return request({
        url: '/admin/project/changeServiceEndTime',
        method: 'POST',
        data
    });
}

/**
 * 设置微信小程序
 */
export function ChangeWechat(data) {
    return request({
        url: '/admin/project/changeWxApp',
        method: 'POST',
        data
    });
}

// 获取合同/协议下拉数据
export function getContractJson(params) {
    return request({
        url: '/admin/project/getContractJson',
        method: 'GET',
        params
    });
}
// 获取方案下拉数据
export function getPlanJson(params) {
    return request({
        url: '/admin/project/getPlanJson',
        method: 'GET',
        params
    });
}
// 获取工种下拉数据
export function getWorkJson(params) {
    return request({
        url: '/admin/project/getWorkJson',
        method: 'GET',
        params
    });
}
// 获取劳务队下拉数据
export function getTeamJson(params) {
    return request({
        url: '/admin/project/getTeamJson',
        method: 'GET',
        params
    });
}
// 获取用户列表
export function UserList(params) {
    return request({
        url: '/admin/project/getUsers',
        method: 'GET',
        params
    });
}
// 强制退场
export function outUser(params) {
    return request({
        url: '/admin/project/outUser',
        method: 'GET',
        params
    });
}

// 获取强制退场理由下拉数据
export function outReasons(params) {
    return request({
        url: '/admin/outReason/getOutReasonJson',
        method: 'GET',
        params
    });
}
