<template>
  <div id="app">
    <a-row>
      <a-col :span="18">
        <div class="amap-page-container">
          <el-amap-search-box
            class="search-box"
            :search-option="searchOption"
            :on-search-result="onSearchResult"
          ></el-amap-search-box>
          <el-amap
            vid="amapDemo"
            :center="center"
            :zoom="zoom"
            :plugin="plugin"
            class="amap-demo"
            :events="events"
            pitch-enable="false"
          >
            <el-amap-marker :position="center" />

            <el-amap-circle
              vid="circle"
              :center="center"
              :radius="radius"
              fill-opacity="0.2"
              strokeColor="#38f"
              strokeOpacity="0.8"
              strokeWeight="1"
              fillColor="#38f"
            ></el-amap-circle>
          </el-amap>
        </div>
      </a-col>
      <a-col :span="6">
        <div style="padding: 0px 10px">
          <a-input
            style="margin-bottom: 10px"
            addon-before="经度"
            v-model="center[0]"
          />
          <a-input
            style="margin-bottom: 10px"
            addon-before="纬度"
            v-model="center[1]"
          />
          <a-input
            style="margin-bottom: 10px"
            addon-before="范围半径"
            v-model="radius"
            addon-after="米"
          />
        </div>
      </a-col>
      <a-col :span="24">
        <div style="text-align: right;padding-top:20px">
          <a-button type="primary" @click="mapOk"> 确认 </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
    
<script>
export default {
  name: "AmapPage",
  data: function () {
    const self = this;
    return {
      searchOption: {
        city: "",
        citylimit: false,
      },
      zoom: 6,
      center: [103.820898, 36.100757],
      markers: [],
      radius: 500,
      windows: [],
      window: "",
      events: {
        click(e) {
          const { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
          self.center = [self.lng, self.lat];
        },
      },
      lng: 103.820898,
      lat: 36.100757,

      /*一些工具插件*/

      plugin: [
        {
          pName: "DistrictSearch",
          events: {
            init(o) {
              console.log(o);
            },
          },
        },
        {
          // 定位
          pName: "Geolocation",
          events: {
            init(o) {
              // o是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  // 设置经度
                  self.lng = result.position.lng;
                  // 设置维度
                  self.lat = result.position.lat;
                  // 设置坐标
                  self.center = [self.lng, self.lat];
                  self.markers.push([self.lng, self.lat]);
                  // load
                  self.loaded = true;
                  // 页面渲染好后
                  self.$nextTick();
                }
              });
            },
          },
        },
        {
          // 工具栏
          pName: "ToolBar",
          events: {
            init(instance) {
              // console.log(instance);
            },
          },
        },
      ],
    };
  },
  props: ["lngs", "lats", "radiuss"],
  mounted() {
    console.log(this.lngs);
    if(this.lngs != null && this.lngs != ""){
        this.zoom = 16;
    }else{
        this.zoom = 6;
    }
    this.lng = this.lngs == null || this.lngs == "" ? "103.820898" : this.lngs;
    this.lat = this.lats == null || this.lats == "" ? "36.100757" : this.lats;
    this.radius = this.radiuss == null || this.radiuss == "" ? 0 : this.radiuss;
    this.center = [this.lng, this.lat];
  },
  methods: {
    onSearchResult(pois) {
      let latSum = 0;
      let lngSum = 0;
      if (pois.length > 0) {
        pois.forEach((poi) => {
          let { lng, lat } = poi;
          lngSum += lng;
          latSum += lat;
          this.markers.push([poi.lng, poi.lat]);
        });
        let mapcenter = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length,
        };
        this.center = [mapcenter.lng, mapcenter.lat];
        this.lng = mapcenter.lng;
        this.lat = mapcenter.lat;
        this.zoom = 16;
      }
    },
    mapOk(){
        let params = {
            lng:this.lng,
            lat:this.lat,
            radius:this.radius
        }
        this.$emit('GetMapData',params)
    }
  },
};
</script>
    
<style scoped>
.amap-demo {
  height: 500px;
}

.amap-page-container {
  position: relative;
}

.detail {
  width: 100%;
  height: 24px;
  color: #fff;
  background-color: #1a73e8;
  position: absolute;
  bottom: 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.search-box {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
</style>